import AdvoxSlider from 'Component/AdvoxSlider';
import ProductReviewItem from 'Component/ProductReviewItem';
import {
    ProductReviewList as SourceProductReviewList
} from 'SourceComponent/ProductReviewList/ProductReviewList.component';
import { ProductType } from 'Type/ProductList';

import './ProductReviewList.style';

/**
 * @class ProductReviewList
 * @namespace Scandipwa/Component/ProductReviewList/Component */
export class ProductReviewList extends SourceProductReviewList {
    static propTypes = {
        product: ProductType.isRequired
    };

    state = {
        showMore: false
    };

    handleToggleShowMore() {
        this.setState((prev) => {
            const value = !prev.showMore;

            if (value === false) {
                document.querySelector('.ProductReviews')?.scrollIntoView();
            }

            return {
                showMore: value
            };
        });
    }

    renderReviews() {
        const { showMore } = this.state;
        const { product: { reviews } } = this.props;

        if (showMore) {
            return reviews.map((reviewItem, i) => (
                <ProductReviewItem
                  reviewItem={ reviewItem }
                    // eslint-disable-next-line react/no-array-index-key
                  key={ i }
                />
            ));
        }

        return reviews.slice(0, 4).map((reviewItem, i) => (
            <ProductReviewItem
              reviewItem={ reviewItem }
                // eslint-disable-next-line react/no-array-index-key
              key={ i }
            />
        ));
    }

    renderShowMoreButton() {
        const { product } = this.props;
        const { showMore } = this.state;

        if (product.reviews && Object.keys(product.reviews).length < 4) {
            return null;
        }

        return (
            <button
              block="ProductReviewList"
              elem="ShowMoreButton"
              mix={ { block: 'Button', mods: { size: 'small', isHollow: true } } }
              onClick={ this.handleToggleShowMore.bind(this) }
            >
                { !showMore ? __('More reviews') : __('Less reviews') }
            </button>
        );
    }

    renderContent() {
        const { device } = this.props;
        const { showMore } = this.state;

        if (showMore) {
            return this.renderReviews();
        }

        if (device.isMobile) {
            return (
                <AdvoxSlider
                  mix={ { block: 'SliderWidget', mix: { block: 'ProductReviewList', elem: 'Slider' } } }
                  variant="none"
                  settings={ {
                      dots: true,
                      arrows: false,
                      speed: 500,
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      infinite: true,
                      draggable: true
                  } }
                >
                    { this.renderReviews() }
                </AdvoxSlider>
            );
        }

        return this.renderReviews();
    }

    render() {
        const { product, device } = this.props;
        const { showMore } = this.state;
        const hasReviews = product.reviews && Object.keys(product.reviews).length > 0;

        if (!hasReviews) {
            return null;
        }

        return (
            <>
                <ul block="ProductReviewList" mods={ { isSlider: device.isMobile && !showMore } }>
                    { this.renderContent() }
                </ul>
                { this.renderShowMoreButton() }
            </>
        );
    }
}

export default ProductReviewList;
