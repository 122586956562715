import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ProductReviewList from 'Component/ProductReviewList';
import ProductReviewRating from 'Component/ProductReviewRating';
import { DeviceType } from 'Type/Device';
import { ProductType } from 'Type/ProductList';
import { showNewReviewPopup } from 'Util/Product';

import './ProductReviews.style';

/** @namespace Scandipwa/Component/ProductReviews/Component */
export class ProductReviews extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        areDetailsLoaded: PropTypes.bool,
        device: DeviceType.isRequired
    };

    static defaultProps = {
        areDetailsLoaded: false
    };

    renderButton() {
        return (
            <button
              block="ProductReviews"
              elem="Button"
              mix={ { block: 'Button' } }
              onClick={ showNewReviewPopup }
            >
                { __('Add review') }
            </button>
        );
    }

    renderNoRating() {
        const { device: { isMobile } } = this.props;

        if (isMobile) {
            return (
                <p>
                    { __('There are no reviews yet! Click button below to submit one!') }
                </p>
            );
        }

        return (
            <p>
                { __('There are no reviews yet! Click button on the right to submit one!') }
            </p>
        );
    }

    renderRatingSchema(percent, reviewCount) {
        return (
            <>
                <meta itemProp="ratingValue" content={ percent } />
                <meta itemProp="worstRating" content={ 0 } />
                <meta itemProp="bestRating" content={ 100 } />
                <meta itemProp="reviewCount" content={ reviewCount } />
            </>
        );
    }

    renderRatingData() {
        const {
            product: {
                review_summary: {
                    rating_summary,
                    review_count
                } = {}
            }
        } = this.props;

        const STARS_COUNT = 5;
        const PERCENT = 100;

        // eslint-disable-next-line no-mixed-operators
        const percent = parseFloat(STARS_COUNT * (rating_summary || 0) / PERCENT).toFixed(2);

        if (!review_count) {
            return this.renderNoRating();
        }

        return (
            <>
                { this.renderRatingSchema(rating_summary, review_count) }
                <ProductReviewRating
                  mix={ { block: 'ProductReviews', elem: 'SummaryRating' } }
                  summary={ rating_summary }
                />
                <p block="ProductReviews" elem="SummaryDetails">
                    { percent }
                    <span>{ STARS_COUNT }</span>
                </p>
            </>
        );
    }

    renderSummary() {
        const {
            product: {
                review_summary: {
                    review_count
                } = {}
            },
            reviewRatings
        } = this.props;

        if (!reviewRatings.length) {
            return null;
        }

        const reviewSchemaObject = review_count
            ? {
                itemType: 'http://schema.org/AggregateRating',
                itemProp: 'aggregateRating',
                itemScope: true
            } : {};

        return (
            <div
              block="ProductReviews"
              elem="Summary"
              { ...reviewSchemaObject }
            >
                { this.renderRatingData() }
            </div>
        );
    }

    renderList() {
        const { product, device } = this.props;

        return (
            <ProductReviewList
              device={ device }
              product={ product }
            />
        );
    }

    render() {
        const { areDetailsLoaded } = this.props;

        const heading = areDetailsLoaded ? __('Reviews') : '';

        if (!heading) {
            return null;
        }

        return (
            <div block="ProductReviews">
                <div block="ProductReviews" elem="Header">
                    <h2 className="TypographyHeader TypographyHeader_heading_h2">{ heading }</h2>
                    { this.renderSummary() }
                    { this.renderButton() }
                </div>
                { this.renderList() }
            </div>
        );
    }
}

export default ProductReviews;
